var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flags-management-modal"
  }, [_c('div', {
    staticClass: "flags-management-modal__bg",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "flags-management-modal__box"
  }, [_c('div', {
    class: ['flags-management-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "flags-management-modal__actions"
  }, [_c('h2', {
    staticClass: "flags-management-modal__header"
  }, [_vm._v("Flags")]), _c('button', {
    staticClass: "flags-management-modal__action flags-management-modal__action--close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "flags-management-modal__inner"
  }, [_c('div', {
    staticClass: "flags-management-modal__body"
  }, [_vm.flags.length == 0 ? _c('p', {
    staticClass: "flags-management-modal__desc"
  }, [_vm._v("There are no flags to display")]) : _c('div', {
    staticClass: "flags-management-modal__options"
  }, _vm._l(_vm.flags, function (flag) {
    return _c('div', {
      key: flag.id,
      staticClass: "flags-management-modal__option"
    }, [_c('sygni-flag', {
      attrs: {
        "color": flag.color
      }
    }), _c('p', {
      staticClass: "flags-management-modal__option-text"
    }, [_vm._v(" " + _vm._s(flag.name) + " "), _vm.showShareIcon(flag) ? _c('img', {
      staticClass: "flags-management-modal__option-icon",
      attrs: {
        "src": _vm._f("getIcon")('Investor'),
        "alt": ""
      }
    }) : _vm._e()]), _vm.actionsEnabled(flag) ? _c('div', {
      staticClass: "flags-management-modal__option-btn",
      on: {
        "click": function click($event) {
          return _vm.openEditMode(flag);
        }
      }
    }, [_vm._v("Edit")]) : _vm._e(), _vm.actionsEnabled(flag) ? _c('div', {
      staticClass: "flags-management-modal__option-btn info",
      on: {
        "click": function click($event) {
          return _vm.openShareMode(flag);
        }
      }
    }, [_vm._v("Share")]) : _vm._e(), _vm.actionsEnabled(flag) ? _c('div', {
      staticClass: "flags-management-modal__option-btn danger",
      on: {
        "click": function click($event) {
          return _vm.deleteFlag(flag.id);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e()], 1);
  }), 0)])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editedFlagId,
      expression: "!editedFlagId"
    }],
    staticClass: "flags-management-modal__footer"
  }, [_c('p', {
    staticClass: "flags-management-modal__header"
  }, [_vm._v("Add")]), _c('div', {
    staticClass: "flags-management-modal__footer-inner"
  }, [_c('div', {
    staticClass: "flags-management-modal__input"
  }, [_c('sygni-input', {
    ref: "newFlagInput",
    attrs: {
      "label": "NEW CATEGORY",
      "validation": _vm.$v.newFlag.name
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addNewFlag.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newFlag.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newFlag, "name", $$v);
      },
      expression: "newFlag.name"
    }
  }), _c('sygni-color-picker', {
    ref: "newFlagColorPicker",
    attrs: {
      "validation": _vm.$v.newFlag.color
    },
    on: {
      "change": _vm.setNewFlagColor
    }
  })], 1), _c('sygni-rect-button', {
    staticClass: "filled black",
    on: {
      "click": _vm.addNewFlag
    }
  }, [_vm._v("Add")])], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editedFlagId,
      expression: "editedFlagId"
    }],
    staticClass: "flags-management-modal__footer"
  }, [_c('div', {
    staticClass: "flags-management-modal__header"
  }, [_c('span', [_vm._v("Edit")]), _c('span', {
    staticClass: "flags-management-modal__close",
    on: {
      "click": _vm.closeEditMode
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "flags-management-modal__footer-inner"
  }, [_c('div', {
    staticClass: "flags-management-modal__input"
  }, [_c('sygni-input', {
    ref: "newFlagInput",
    attrs: {
      "label": "EDIT CATEGORY",
      "validation": _vm.$v.editedFlag.name
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.putNewFlag.apply(null, arguments);
      }
    },
    model: {
      value: _vm.editedFlag.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedFlag, "name", $$v);
      },
      expression: "editedFlag.name"
    }
  }), _c('sygni-color-picker', {
    ref: "editedFlagColorPicker",
    attrs: {
      "validation": _vm.$v.editedFlag.color
    },
    on: {
      "change": _vm.setEditedFlagColor
    }
  })], 1), _c('sygni-rect-button', {
    staticClass: "filled black",
    on: {
      "click": _vm.putNewFlag
    }
  }, [_vm._v("Edit")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }