var render = function () {
  var _vm$enabledActions, _vm$enabledActions2, _vm$enabledActions3, _vm$enabledActions4, _vm$activeDocument, _vm$activeDocument2, _vm$activeUserData, _vm$activeUserData$ro, _vm$activeUserData$ro2, _vm$activeUserData2, _vm$activeUserData2$r, _vm$activeUserData2$r2, _vm$activeUserData3, _vm$activeUserData3$r, _vm$activeUserData3$r2, _vm$activeDocument3, _vm$activeDocument4, _vm$activeDocument5, _vm$enabledActions5, _vm$enabledActions6, _vm$sendToApprovalErr, _vm$enabledActions7, _vm$enabledActions8, _vm$enabledActions9, _vm$enabledActions10, _vm$enabledActions11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preview-doc"
  }, [_c('portal', {
    attrs: {
      "to": "actions"
    }
  }, [(_vm$enabledActions = _vm.enabledActions) !== null && _vm$enabledActions !== void 0 && _vm$enabledActions.includes('edit') || (_vm$enabledActions2 = _vm.enabledActions) !== null && _vm$enabledActions2 !== void 0 && _vm$enabledActions2.includes('preview') || (_vm$enabledActions3 = _vm.enabledActions) !== null && _vm$enabledActions3 !== void 0 && _vm$enabledActions3.includes('update-description') || (_vm$enabledActions4 = _vm.enabledActions) !== null && _vm$enabledActions4 !== void 0 && _vm$enabledActions4.includes('edit-payment-status') && ((_vm$activeDocument = _vm.activeDocument) === null || _vm$activeDocument === void 0 ? void 0 : _vm$activeDocument.status) === 'approved' || ((_vm$activeDocument2 = _vm.activeDocument) === null || _vm$activeDocument2 === void 0 ? void 0 : _vm$activeDocument2.status) === 'booked' ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        title: _vm.isUltimatelyFailed ? 'Incorrect buyer tax number. You can only delete the document.' : '',
        boundary: 'viewport'
      },
      expression: "{ title: isUltimatelyFailed ? 'Incorrect buyer tax number. You can only delete the document.' : '', boundary: 'viewport' }",
      modifiers: {
        "hover": true
      }
    }],
    class: ['preview-doc__action', _vm.isUltimatelyFailed ? 'unabled' : ''],
    on: {
      "click": _vm.editFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('EDIT'),
      "alt": _vm.$route.name == 'accounting-by-id' ? 'Save changes' : 'Edit document'
    }
  })]), _c('span', {
    staticClass: "preview-doc__action-label"
  }, [_vm._v(_vm._s(_vm.$route.name == 'accounting-by-id' ? 'Save' : (_vm$activeUserData = _vm.activeUserData) !== null && _vm$activeUserData !== void 0 && (_vm$activeUserData$ro = _vm$activeUserData.role) !== null && _vm$activeUserData$ro !== void 0 && (_vm$activeUserData$ro2 = _vm$activeUserData$ro.workflow) !== null && _vm$activeUserData$ro2 !== void 0 && _vm$activeUserData$ro2.includes('approver') ? 'Preview' : 'Edit'))])]) : _vm._e(), _vm.$route.name === 'accounting-by-id' ? _c('button', {
    staticClass: "preview-doc__action",
    on: {
      "click": function click($event) {
        return _vm.$emit('reportDocument');
      }
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('LETTER'),
      "alt": "Report document"
    }
  })]), _c('span', {
    staticClass: "preview-doc__action-label text-danger"
  }, [_vm._v("Report problem")])]) : _vm._e(), _vm.$route.name === 'accounting-by-id' && ((_vm$activeUserData2 = _vm.activeUserData) !== null && _vm$activeUserData2 !== void 0 && (_vm$activeUserData2$r = _vm$activeUserData2.role) !== null && _vm$activeUserData2$r !== void 0 && (_vm$activeUserData2$r2 = _vm$activeUserData2$r.workflow) !== null && _vm$activeUserData2$r2 !== void 0 && _vm$activeUserData2$r2.includes('admin') || (_vm$activeUserData3 = _vm.activeUserData) !== null && _vm$activeUserData3 !== void 0 && (_vm$activeUserData3$r = _vm$activeUserData3.role) !== null && _vm$activeUserData3$r !== void 0 && (_vm$activeUserData3$r2 = _vm$activeUserData3$r.workflow) !== null && _vm$activeUserData3$r2 !== void 0 && _vm$activeUserData3$r2.includes('accountant')) && (_vm$activeDocument3 = _vm.activeDocument) !== null && _vm$activeDocument3 !== void 0 && _vm$activeDocument3.glAccountDiffWithMapping ? _c('button', {
    staticClass: "preview-doc__action",
    on: {
      "click": function click($event) {
        return _vm.$emit('requestGlChange');
      }
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('LETTER'),
      "alt": "Request change GL"
    }
  })]), _c('span', {
    staticClass: "preview-doc__action-label text-danger"
  }, [_vm._v("Request change GL")])]) : _vm._e(), _c('div', {
    staticClass: "preview-doc__action preview-doc__action--flags"
  }, [_c('sygni-flag-picker', {
    attrs: {
      "document": _vm.activeDocument
    },
    on: {
      "openFlagManagementModal": function openFlagManagementModal($event) {
        return _vm.$emit('openFlagManagementModal');
      },
      "changeFlag": function changeFlag($event) {
        return _vm.$emit('changeFlag', $event);
      }
    }
  })], 1), _vm.$route.name != 'accounting-by-id' ? _c('div', {
    staticClass: "preview-doc__action preview-doc__action--status"
  }, [_c('sygni-rounded-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.getTooltipStatusMessage(_vm.activeDocument),
      expression: "getTooltipStatusMessage(activeDocument)",
      modifiers: {
        "hover": true
      }
    }],
    class: ['outline round', _vm.getDocumentStatusClassName((_vm$activeDocument4 = _vm.activeDocument) === null || _vm$activeDocument4 === void 0 ? void 0 : _vm$activeDocument4.status)],
    attrs: {
      "hoverable": false
    }
  }, [_vm._v(_vm._s(_vm.statusText((_vm$activeDocument5 = _vm.activeDocument) === null || _vm$activeDocument5 === void 0 ? void 0 : _vm$activeDocument5.status)))])], 1) : _vm._e(), (_vm$enabledActions5 = _vm.enabledActions) !== null && _vm$enabledActions5 !== void 0 && _vm$enabledActions5.includes('send-to-approval') ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.shouldSend ? 'Send for Approval' : 'Approve',
      expression: "shouldSend ? 'Send for Approval' : 'Approve'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "preview-doc__action preview-doc__action--square",
    on: {
      "click": _vm.sendFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_vm.shouldSend ? _c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE'),
      "alt": 'Send for Approval'
    }
  }) : _c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE 2'),
      "alt": 'Approve'
    }
  })])]) : _vm._e(), (_vm$enabledActions6 = _vm.enabledActions) !== null && _vm$enabledActions6 !== void 0 && _vm$enabledActions6.includes('send-to-approval-readonly') && (_vm$sendToApprovalErr = _vm.sendToApprovalErrors) !== null && _vm$sendToApprovalErr !== void 0 && _vm$sendToApprovalErr.length ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.sendToApprovalErrorsText,
      expression: "sendToApprovalErrorsText",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "preview-doc__action preview-doc__action--square preview-doc__action--disabled"
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE'),
      "alt": "Send for approval"
    }
  })])]) : _vm._e(), (_vm$enabledActions7 = _vm.enabledActions) !== null && _vm$enabledActions7 !== void 0 && _vm$enabledActions7.includes('approve') && _vm.$route.name == 'accounting-by-id' ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Approve document',
      expression: "'Approve document'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "preview-doc__action preview-doc__action--square",
    on: {
      "click": _vm.approveDocument
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE 2'),
      "alt": "Approve document"
    }
  })])]) : _vm._e(), (_vm$enabledActions8 = _vm.enabledActions) !== null && _vm$enabledActions8 !== void 0 && _vm$enabledActions8.includes('reject') && _vm.$route.name == 'accounting-by-id' ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Reject document',
      expression: "'Reject document'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "preview-doc__action preview-doc__action--square",
    on: {
      "click": _vm.rejectDocument
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('REJECT 2'),
      "alt": "Reject document"
    }
  })])]) : _vm._e(), _c('button', {
    staticClass: "preview-doc__action preview-doc__action--square"
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOTS'),
      "alt": "'Actions'"
    }
  })]), _c('div', {
    staticClass: "preview-doc-dropdown"
  }, [_c('div', {
    staticClass: "preview-doc-dropdown__inner"
  }, [(_vm$enabledActions9 = _vm.enabledActions) !== null && _vm$enabledActions9 !== void 0 && _vm$enabledActions9.includes('book') ? _c('div', {
    staticClass: "preview-doc-dropdown__option"
  }, [_c('button', {
    staticClass: "preview-doc__action",
    on: {
      "click": _vm.bookFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('BOOK'),
      "alt": "Book"
    }
  })]), _c('span', {
    staticClass: "preview-doc__action-label"
  }, [_vm._v("Book document")])])]) : _vm._e(), (_vm$enabledActions10 = _vm.enabledActions) !== null && _vm$enabledActions10 !== void 0 && _vm$enabledActions10.includes('generate-xml-payment') ? _c('div', {
    staticClass: "preview-doc-dropdown__option"
  }, [_c('button', {
    staticClass: "preview-doc__action",
    on: {
      "click": _vm.generatePaymentPackageFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('WALLET'),
      "alt": "Generate payment package"
    }
  })]), _c('span', {
    staticClass: "preview-doc__action-label"
  }, [_vm._v("Generate payment package")])])]) : _vm._e(), _c('div', {
    staticClass: "preview-doc-dropdown__option"
  }, [_c('button', {
    staticClass: "preview-doc__action",
    on: {
      "click": _vm.downloadFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOWNLOAD'),
      "alt": "Download"
    }
  })]), _c('span', {
    staticClass: "preview-doc__action-label"
  }, [_vm._v("Download")])])]), (_vm.$route.name === 'accounting-by-id' || _vm.$route.name === 'accounting') && (_vm$enabledActions11 = _vm.enabledActions) !== null && _vm$enabledActions11 !== void 0 && _vm$enabledActions11.includes('delete') ? _c('div', {
    staticClass: "preview-doc-dropdown__option"
  }, [_c('button', {
    staticClass: "preview-doc__action",
    on: {
      "click": _vm.deleteFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DELETE'),
      "alt": "Delete document"
    }
  })]), _c('div', {
    staticClass: "preview-doc__action-label"
  }, [_vm._v("Delete")])])]) : _vm._e()])])]), _c('button', {
    staticClass: "preview-doc__action preview-doc__action--arrow-left",
    on: {
      "click": _vm.prevFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Prev"
    }
  })])]), _c('button', {
    staticClass: "preview-doc__action preview-doc__action--arrow-right",
    on: {
      "click": _vm.nextFn
    }
  }, [_c('div', {
    staticClass: "preview-doc__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Next"
    }
  })])]), _c('button', {
    staticClass: "preview-doc__action preview-doc__action--close",
    on: {
      "click": _vm.closeDocument
    }
  }, [_vm._v("Close")])]), !_vm.simple ? _c('div', {
    staticClass: "preview-doc__header"
  }, [_c('portal-target', {
    attrs: {
      "name": "actions"
    }
  })], 1) : _vm._e(), _c('div', {
    class: ['preview-doc__container', _vm.zoomControl ? 'blocked' : ''],
    style: "min-height: ".concat(_vm.minHeight, ";"),
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.handleNote(true);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.handleNote(false);
      },
      "mousewheel": _vm.handleScrollControl
    }
  }, [_c('div', {
    ref: "pdfContainer",
    attrs: {
      "id": "pdfContainer"
    }
  }, _vm._l(_vm.pages, function (i) {
    return _c('pdf', {
      key: "".concat(_vm.dynamicKey, "-").concat(i),
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "src": _vm.pdfData,
        "rotate": _vm.rotation,
        "page": i,
        "text": true,
        "annotation": true,
        "scale": _vm.scale
      }
    });
  }), 1), !_vm.simple ? _c('div', {
    staticClass: "preview-doc__controls"
  }, [_c('div', {
    staticClass: "preview-doc__control"
  }, [_c('sygni-circle-button', {
    staticClass: "lead bordered filled",
    on: {
      "click": function click($event) {
        return _vm.zoomIn(0.05);
      }
    }
  }, [_vm._v("+")])], 1), _c('div', {
    staticClass: "preview-doc__control"
  }, [_c('sygni-circle-button', {
    staticClass: "lead bordered filled",
    on: {
      "click": function click($event) {
        return _vm.zoomOut(0.05);
      }
    }
  }, [_vm._v("-")])], 1), _c('div', {
    staticClass: "preview-doc__control"
  }, [_c('sygni-circle-button', {
    staticClass: "lead bordered filled",
    on: {
      "click": function click($event) {
        return _vm.rotate(90);
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "width": "24px",
      "fill": "#000000"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.55 5.55L11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z"
    }
  })])])], 1)]) : _vm._e(), _vm.isLoading ? _c('div', {
    staticClass: "preview-doc__mask"
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]) : _vm._e(), _c('div', {
    class: ['preview-doc__note', _vm.showNote ? 'active' : '']
  }, [_vm._v("Hold " + _vm._s(_vm.operatingSystem == 'MacOS' ? 'cmd' : 'alt') + " to zoom in / zoom out")])]), !_vm.simple ? _c('div', {
    staticClass: "preview-doc__footer"
  }, [_c('portal-target', {
    attrs: {
      "name": "actions"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }