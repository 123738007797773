import { helpers } from "vuelidate/lib/validators";

export function phone (value: string) {
  if(!value) return true;
  return Boolean(value?.match(/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/));
}

// export const greaterThan = (comparisonValue: number) => (value: number) => {
//   return value > comparisonValue ? true : false
// }

export const greaterThan = (param: number) => helpers.withParams({ type: 'comparisonValue', comparisonValue: param }, (value: number) => value > param)

// export function iban(value: string) {
//   return isValidIBANNumber(value);
// }

export const hasCorrectAlready = (correctNumber: any, listOfReports: any) => (value: any) => {
  const result: boolean = listOfReports?.find((report: any) => report.correctionNumber === correctNumber) ? true : false

  return !(result)
}

export const taxIdentificationNumber = (param: string) => helpers.withParams({ type: 'comparisonValue', comparisonValue: param }, () => Boolean(checkVatNumber(param)))

export const iban = (country: string) => (value: string) => isValidIBANNumber(value, country);

/*
 * Returns 1 if the IBAN is valid 
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input: any, country: string) {
  if(input.value === '') return false;
  if(input.value !== undefined && !input.value) return true;

  input = input.value ? input.value : input;

  const containsCode = (/^[A-Za-z]+$/).test(input.substring(0, 1)) || (/^[A-Za-z]+$/).test(input.substring(1, 2));

  if(containsCode) {
    input = input.replace(/^.{2}/g, country);
  }

  const CODE_LENGTHS : any = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
    AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25, SV: 28,
    TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  };

  const iban: any = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code: any = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }

  // rearrange country code and check digits, and convert chars to ints
  const digits: any = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: any) => {
    return letter.charCodeAt(0) - 55;
  });

  // final check
  return mod97(digits) === 1;
}

function mod97(string: string) {
  let checksum: number | string = string.slice(0, 2), fragment;

  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
}

function checkVatNumber (toCheck: string) {
  if (toCheck?.length === 0) return true

  // tablica regexów do sprawdzania VATNo
  const vatexp: any[] = []
  // dodawanie regexa do tablicy
  vatexp.push(/^(PL)(\d{10})$/);                             //** Poland
  vatexp.push(/^(AT)U(\d{8})$/);                             //** Austria
  vatexp.push(/^(BE)(0?\d{9}|\d{10})$/);                     //** Belgium
  vatexp.push(/^(BG)(\d{9,10})$/);                           //** Bulgaria
  vatexp.push(/^(CHE)(\d{9})(MWST|TVA|IVA)?$/);              //** Switzerland 
  vatexp.push(/^(CY)([0-59]\d{7}[A-Z])$/);                   //** Cyprus
  vatexp.push(/^(CZ)(\d{8})$/);                              //** Czech Republic
  vatexp.push(/^(DE)([1-9]\d{8})$/);                         //** Germany 
  vatexp.push(/^(DK)(\d{8})$/);                              //** Denmark 
  vatexp.push(/^(EE)(10\d{7})$/);                            //** Estonia 
  vatexp.push(/^(EL)(0?\d{8}|\d{9})$/);                      //** Greece 
  vatexp.push(/^(ES)([A-Z]\d{8})$/);                         //** Spain (National juridical entities)
  vatexp.push(/^(ES)([A-HN-SW]\d{7}[A-J])$/);                //** Spain (Other juridical entities)
  vatexp.push(/^(FI)(\d{8})$/);                              //** Finland
  vatexp.push(/^(FR)(\d{11})$/);                             //** France
  vatexp.push(/^(GB)?(\d{9})$/);                             //** UK (Standard)
  vatexp.push(/^(GB)?(\d{12})$/);                            //** UK (Branches)
  vatexp.push(/^(GB)?(GD\d{3})$/);                           //** UK (Government)
  vatexp.push(/^(GB)?(HA\d{3})$/);                           //** UK (Health authority)
  vatexp.push(/^(HR)(\d{11})$/);                             //** Croatia
  vatexp.push(/^(HU)(\d{8})$/);                              //** Hungary 
  vatexp.push(/^(IE)(\d{7}[A-W])$/);                         //** Ireland (1)
  // eslint-disable-next-line no-useless-escape
  vatexp.push(/^(IE)([7-9][A-Z\*\+)]\d{5}[A-W])$/);          //** Ireland (2)
  vatexp.push(/^(IE)(\d{7}[A-W][AH])$/);                     //** Ireland (3) 
  vatexp.push(/^(IT)(\d{11})$/);                             //** Italy 
  vatexp.push(/^(LV)(\d{11})$/);                             //** Latvia 
  vatexp.push(/^(LT)(\d{12})$/);                             //** Lithunia
  vatexp.push(/^(LU)(\d{8})$/);                              //** Luxembourg 
  vatexp.push(/^(MT)([1-9]\d{7})$/);                         //** Malta
  vatexp.push(/^(NL)(\d{9})B\d{2}$/);                        //** Netherlands
  vatexp.push(/^(NO)(\d{9})$/);                              //** Norway (not EU)
  vatexp.push(/^(PT)(\d{9})$/);                              //** Portugal
  vatexp.push(/^(RO)([1-9]\d{1,9})$/);                       //** Romania
  vatexp.push(/^(RU)(\d{10}|\d{12})$/);                      //** Russia
  vatexp.push(/^(RS)(\d{9})$/);                              //** Serbia
  vatexp.push(/^(SI)([1-9]\d{7})$/);                         //** Slovenia
  vatexp.push(/^(SK)([1-9]\d[2346-9]\d{7})$/);               //** Slovakia Republic
  vatexp.push(/^(SE)(\d{10}01)$/);                           //** Sweden
  vatexp.push(/^(NZ)(\d{13})$/);                             //** New Zealand

  // przypisanie VATNo do zmiennej przy jednoczesnej zmianie na wielkie litery
  let VATNumber = toCheck?.toUpperCase();
  // pozbycie się białych znaków, kropek i myślników
  VATNumber = VATNumber?.replace(/(\s|-|\.)+/g, '');
  // defaultowo przypisanie do zmiennej zwracającej informacje o walidacji wartości false
  let valid: string | boolean = false;
  
  // sprawdzenie po tablicy regexów
  for (let i = 0; i < vatexp.length; i++) {
    // czy rexex z tablicy pasuje do sprawdzanego VATu

    if (vatexp[i].test(VATNumber)) {
      // Jeśli znajdziemy pasujący regex
      const cCode: string = RegExp.$1; // przypisujemy do zmiennej kod kraju
      const cNumber: string = RegExp.$2; // przypisujemy do zmiennej numer VAT
      // Wywołanie funkcji walidującej dla właściwego kraju opierając się ka kodzie kraju z VATNo
      if (eval(`${cCode}VATCheckDigit('${String(cNumber)}')`)) {
        valid = VATNumber;
      }

      // Wyjście z pętli
      break;
    }
  }

  // Return with either an error or the reformatted VAT number
  // eslint-disable-next-line no-extra-boolean-cast
  return !!(valid);
}

function PLVATCheckDigit (vatnumber: string) {
  let total = 0
  const multipliers = [6,5,7,2,3,4,5,6,7]
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala
  for (let i = 0; i < 9; i++) total += Number(String(vatnumber)?.charAt(i)) * multipliers[i]
  // Obliczamy liczbę kontrolną
  total = total % 11
  // Jeśli liczba kontrolna jest większa od 9 wówczas ustalamy ją jako 0
  if (total > 9) total = 0
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(9, 10))) { 
    return true 
  } else {
    return false
  }
}

function ATVATCheckDigit (vatnumber: string) {
  let total = 0
  const multipliers = [1,2,1,2,1,2,1]
  let temp = 0
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala  
  for (let i = 0; i < 7; i++) {
    temp = Number(String(vatnumber)?.charAt(i)) * multipliers[i];
    total += Math.floor(temp / 10) + temp % 10;
  }
  // Obliczamy liczbę kontrolną
  total = 10 - (total + 4) % 10; 
  // Jeśli liczba kontrolna jest równy 10 to ustalamy jako 0
  if (total == 10) total = 0
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(7, 8))) {
    return true
  } else {
    return false
  }
}

function BEVATCheckDigit (vatnumber: string) {
  // Dla krótszych numerów VATNo zostało dodane 0 na początku
  if (vatnumber?.length == 9) vatnumber = `0${vatnumber}`
  if (String(vatnumber)?.slice(1, 2) == '0') {
    return false
  }
  // Obliczamy liczbę kontrolną i porównujemy wynik z liczbą kontrolną z VATNo
  if (97 - Number(String(vatnumber)?.slice(0, 8)) % 97 == Number(String(vatnumber)?.slice(8, 10))) {
    return true
  } else {
    return false
  }
}

function BGVATCheckDigit (vatnumber: string) {
  if (vatnumber?.length == 9) {
    // Każda cyfra z VATNo jest mnożona przez jej pozycję (liczoną od 1) i dodawana do totala  
    let total = 0;  
    let temp = 0;
    for (let i = 0; i < 8; i++) {
      temp += Number(String(vatnumber)?.charAt(i)) * (i+1)
    }
    // Obliczamy liczbę kontrolną
    total = temp % 11; 
    // Porównujemy wynik z cyfrą kontrolną z VATNo
    if (total != 10) {
      if (total == Number(String(vatnumber)?.slice(8))) {
        return true
      } else {
        return false
      }
    }

    // Każda cyfra z VATNo jest mnożona przez jej pozycję +3 i dodawana do totala
    temp = 0;
    for (let i = 0; i < 8; i++) {
      temp += Number(String(vatnumber)?.charAt(i)) * (i+3)
    }
    // Obliczamy liczbę kontrolną
    total = temp % 11;
    if (total == 10) total = 0
    
    // Porównujemy wynik z cyfrą kontrolną z VATNo
    if (total == Number(String(vatnumber)?.slice(8))) {
      return true
    } else {
      return false
    }
  }
}

function CHEVATCheckDigit (vatnumber: string) {
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala  
  const multipliers = [5,4,3,2,7,6,5,4];    
  let total = 0;
  for (let i = 0; i < 8; i++) {
    total += Number(String(vatnumber)?.charAt(i)) * multipliers[i]
  }
  // Obliczamy liczbę kontrolną
  total = 11 - total % 11;
  // Liczba kontrolna musi być różna od 10
  if (total == 10) {return false;}
  // Jeśli liczba kontrolna wynosi 11 wówczas zmieniamy ją na 0
  if (total == 11) {total = 0;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.substr(8, 1))) {
    return true
  } else {
    return false
  }
}

function CYVATCheckDigit (vatnumber: string) {
  // Nie może zaczynać się od liczby '12'
  if (Number(String(vatnumber)?.slice(0, 2)) == 12) {
    return false
  }

  // Każda cyfra z VATNo na pozycji parzystej jest zamieniana na inną cyfrę, na pozycji nieparzystej jest wykorzystana do obliczeń, a następnie sumowana
  let total: string | number = 0;
  for (let i = 0; i < 8; i++) {
    let temp = Number(String(vatnumber)?.charAt(i));
    if (i % 2 == 0) {
      switch (temp) {
        case 0:
          temp = 1;
          break;
        case 1:
          temp = 0;
          break;
        case 2:
          temp = 5;
          break;
        case 3:
          temp = 7;
          break;
        case 4: 
          temp = 9; 
          break;
        default: 
          temp = temp * 2 + 3;
          break;
      }
    }
    total += temp
  }

  // Obliczamy liczbę kontrolną, a następnie z jej pomocą znajdujemy znak z tablicy ASCII po decimal value
  total = total % 26;
  total = String?.fromCharCode(total + 65);


  // Porównujemy wynik z ostatnią literą z VATNo
  if (total == String(vatnumber)?.substr(8, 1)) {
    return true
  } else {
    return false
  }
}

function CZVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [8,7,6,5,4,3,2];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala  
  for (let i = 0; i < 7; i++) {
    total += Number(String(vatnumber)?.charAt(i)) * multipliers[i]
  }
  // Obliczamy liczbę kontrolną
  total = 11 - total % 11
  if (total == 10) total = 0
  if (total == 11) total = 1 
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(7,8))) {
    return true
  } else {
    return false
  }
}

function DEVATCheckDigit (vatnumber: string) {
  let product = 10;
  let sum = 0;     
  let checkdigit = 0;                      
  for (let i = 0; i < 8; i++) {
    // Każda cyfra z VATNo jest wykorzystywana do obliczeń
    sum = (Number(String(vatnumber)?.charAt(i)) + product) % 10;
    if (sum == 0) sum = 10
    product = (2 * sum) % 11
  }
  // Obliczamy liczbę kontrolną 
  if (11 - product == 10) {
    checkdigit = 0
  } else {
    checkdigit = 11 - product
  }

  // Porównujemy wynik z liczbą kontrolną z VATNo
  if (checkdigit == Number(String(vatnumber)?.slice(8, 9))) {
    return true
  } else {
    return false
  }
}

function DKVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [2,7,6,5,4,3,2,1];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala  
  for (let i = 0; i < 8; i++) {
    total += Number(String(vatnumber)?.charAt(i)) * multipliers[i]
  }
  // Obliczamy liczbę kontrolną 
  total = total % 11;
  // Liczba kontrolna powinna wynosić zero
  if (total == 0) {
    return true
  } else {
    return false
  }
}

function EEVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [3,7,1,3,7,1,3,7];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala  
  for (let i = 0; i < 8; i++) {
    total += Number(String(vatnumber)?.charAt(i)) * multipliers[i]
  }
  // Obliczamy liczbę kontrolną 
  total = 10 - total % 10;
  if (total == 10) {total = 0;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(8, 9))) {
    return true
  } else {
    return false
  }
}

function ELVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [256,128,64,32,16,8,4,2];
  // Dla krótszych numerów VATNo zostało dodane 0 na początku
  if (vatnumber?.length == 8) {
    vatnumber = `0${vatnumber}`
  }
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala  
  for (let i = 0; i < 8; i++) {
    total += Number(String(vatnumber)?.charAt(i)) * multipliers[i]
  }
  // Obliczamy liczbę kontrolną
  total = total % 11;
  if (total > 9) total = 0
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(8, 9))) {
    return true
  } else {
    return false
  }
}

function ESVATCheckDigit (vatnumber: string) {
  let total: string | number = 0; 
  let temp = 0;
  const multipliers = [2,1,2,1,2,1,2];
  const esexp = new Array ();
  esexp[0] = (/^[A-H|J|U|V]\d{8}$/);
  esexp[1] = (/^[A-H|N-S|W]\d{7}[A-J]$/);
  let i = 0;
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala  
  for (i = 0; i < 7; i++) {
    temp = Number(String(vatnumber)?.charAt(i+1)) * multipliers[i];
    if (temp > 9) {total += Math.floor(temp/10) + temp%10;} else {total += temp;}
  } 

  // Krajowe podmioty prawne
  if (esexp[0].test(vatnumber)) {
    // Obliczamy liczbę kontrolną 
    total = 10 - total % 10;
    if (total == 10) {total = 0;}          
    // Porównujemy wynik z cyfrą kontrolną z VATNo
    if (total == Number(String(vatnumber)?.slice(8,9))) {return true;} else {return false;}
  } 
  // Inne podmioty prawne
  else if (esexp[1].test(vatnumber)) {
    // Obliczamy liczbę kontrolną, a następnie z jej pomocą znajdujemy znak z tablicy ASCII po decimal value
    total = 10 - total % 10;
    total = String?.fromCharCode(total+64);
    // Porównujemy wynik z literą kontrolną z VATNo
    if (total == String(vatnumber)?.slice(8,9)) {return true;} else {return false;} 
  }
}

function FIVATCheckDigit (vatnumber: string) {
  let total = 0; 
  const multipliers = [7,9,10,5,8,4,2];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 7; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną 
  total = 11 - total % 11;
  if (total > 9) {total = 0;}  
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(7,8))) {return true;} else {return false;}
}

function FRVATCheckDigit (vatnumber: string) {
  // Pobieramy ostatnie 9 cyfr VATNo
  let total = Number(String(vatnumber)?.substring(2)); 
  // Obliczamy liczbę kontrolną 
  total = (total*100+12) % 97;
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(0,2))) {return true;} else {return false;}
}

function GBVATCheckDigit (vatnumber: string) {
  const multipliers = [8,7,6,5,4,3,2];
  // Government
  if (String(vatnumber)?.substr(0,2) == 'GD') { 
    if (Number(String(vatnumber)?.substr(2,3)) < 500) {return true;} else {return false;}
  }

  // Health
  if (String(vatnumber)?.substr(0,2) == 'HA') {
    if (Number(String(vatnumber)?.substr(2,3)) > 499) {return true;} else {return false;}
  }

  let total = 0;
  // VATNo nie zaczyna się od 0
  if (Number(String(vatnumber)?.slice(0)) == 0) {return false;}
  const no = Number(String(vatnumber)?.slice(0,7));
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 7; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną 
  let cd = total;
  while (cd > 0) {cd = cd - 97;}  
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  cd = Math.abs(cd);
  if (cd == Number(String(vatnumber)?.slice(7,9)) && no < 9990001 && (no < 100000 || no > 999999) && (no < 9490001 || no > 9700000)) return true;
  // Obliczamy liczbę kontrolną drugą metodą
  if (cd >= 55) {cd = cd - 55;} else {cd = cd + 42;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (cd == Number(String(vatnumber)?.slice(7,9)) && no > 1000000) {return true;} else {return false;}
}

function HRVATCheckDigit (vatnumber: string) {
  let product = 10;
  let sum = 0;     
  const checkdigit = 0; 
  // Każda cyfra z VATNo jest wykorzystywana do obliczeń
  for (let i = 0; i < 10; i++) {
    sum = (Number(String(vatnumber)?.charAt(i)) + product) % 10;
    if (sum == 0) {sum = 10;}
    product = (2 * sum) % 11;
  }
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if ((product + Number(String(vatnumber)?.slice(10,11))*1) % 10== 1) {return true;} else {return false;}
}

function HUVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [9,7,3,1,9,7,3];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 7; i++) total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];
  // Obliczamy liczbę kontrolną drugą metodą
  total = 10 - total % 10; 
  if (total == 10) {total = 0;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(7,8))) {return true;} else {return false;}
}

function IEVATCheckDigit (vatnumber: string) {
  let total: string | number = 0; 
  const multipliers = [8,7,6,5,4,3,2];  
  // Konwertujemy stary(typ 1) na nowy format
  // eslint-disable-next-line no-useless-escape
  if (/^\d[A-Z\*\+]/.test(vatnumber)) {
    vatnumber = "0" + String(vatnumber)?.substring(2,7) + String(vatnumber)?.substring(0,1) + String(vatnumber)?.substring(7,8);
  }
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 7; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Jeśli VatNO jest w 3 typie
  if (/^\d{7}[A-Z][AH]$/.test(vatnumber)) {
    // Jeśli litera z 8 pozycji wynosi H to dodajemy 8*9, jeśli nie to 1*9
    if (String(vatnumber)?.charAt(8) == 'H') {total += 72;} else {total += 9;}
  }
  // Obliczamy liczbę kontrolną
  total = total % 23;
  if (total == 0) {total = "W";} else {total = String.fromCharCode(total+64);}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == String(vatnumber)?.slice(7,8)) {return true;} else {return false;}
}

function ITVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [1,2,1,2,1,2,1,2,1,2];
  let temp;
  // Ostatnie 3 pozycje z VATNo muszą być mniejsze od 201 za wyjątkiem 999 i 888
  if (Number(String(vatnumber)?.slice(0,7))==0) {return false;}
  temp = Number(String(vatnumber)?.slice(7,10));
  if ((temp<1) || (temp>201) && temp != 999 && temp != 888) {return false;}
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 10; i++) {
    temp = Number(String(vatnumber)?.charAt(i)) * multipliers[i];
    if (temp > 9) {total += Math.floor(temp/10) + temp%10;} else {total += temp;}
  }
  // Obliczamy liczbę kontrolną
  total = 10 - total % 10;
  if (total > 9) {total = 0;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(10,11))) {return true;} else {return false;}
}

function LTVATCheckDigit (vatnumber: string) {
  // 11 cyfra musi być 1
  if (!(/^\d{10}1/).test(vatnumber)) return false;
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  let total = 0;
  let multipliers = [1,2,3,4,5,6,7,8,9,1,2];
  for (let i = 0; i < 11; i++) {
    total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];
  }
  // Kiedy liczba kontrolna jest równa 10 wykonujemy ponownie działania z użyciem innych multipliers
  if (total % 11 == 10) {
    multipliers = [3,4,5,6,7,8,9,1,2,3,4];
    total = 0;
    for (let i = 0; i < 11; i++) {
      total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];
    }
  }
  // Obliczamy liczbę kontrolną
  total = total % 11;
  if (total == 10) total = 0
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(11,12))) {return true;} else {return false;}
}

function LUVATCheckDigit (vatnumber: string) {
  // Obliczamy liczbę kontrolną i porównujemy wynik z cyfrą kontrolną z VATNo
  if (Number(String(vatnumber)?.slice(0,6)) % 89 == Number(String(vatnumber)?.slice(6,8))) {return true;} else {return false;}
}

function LVVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [9,1,4,8,3,10,2,5,7,6];
  
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 10; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną
  if (total % 11 == 4 && Number(vatnumber[0]) == 9) {total = total - 45;}
  if (total % 11 == 4) { total = 4 - total % 11;}
  else if (total % 11 > 4) { total = 14 - total % 11;}
  else if (total % 11 < 4) { total = 3 - total % 11;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(10,11))) {return true;} else {return false;}
}

function MTVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [3,4,6,7,8,9];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 6; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną
  total = 37 - total % 37;
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(6,8)) * 1) {return true;} else {return false;}
}

function NLVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [9,8,7,6,5,4,3,2];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 8; i++) total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];
  // Obliczamy liczbę kontrolną
  total = total % 11;
  if (total > 9) {total = 0;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(8,9))) {return true;} else {return false;}
}

function NOVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [3,2,7,6,5,4,3,2];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 8; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną
  total = 11 - total % 11;
  if (total == 11) {total = 0;}
  if (total < 10) {  
    // Porównujemy wynik z cyfrą kontrolną z VATNo
    if (total == Number(String(vatnumber)?.slice(8,9))) {return true;} else {return false;}
  }
}

function PTVATCheckDigit (vatnumber: string) {
  let total = 0;
  const multipliers = [9,8,7,6,5,4,3,2];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 8; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną
  total = 11 - total % 11;
  if (total > 9) {total = 0;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(8,9))) {return true;} else {return false;}
}

function ROVATCheckDigit (vatnumber: string) {
  let multipliers = [7,5,3,2,1,7,5,3,2];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  const VATlen = String(vatnumber)?.length;
  multipliers = multipliers.slice (10-VATlen);
  let total = 0;
  for (let i = 0; i < String(vatnumber)?.length-1; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną
  total = (10 * total) % 11;
  if (total == 10) {total = 0;}
  // Porównujemy wynik z cyfrą kontrolną z VATNo
  if (total == Number(String(vatnumber)?.slice(String(vatnumber)?.length - 1, String(vatnumber)?.length))) {return true;} else {return false;}
}

function RSVATCheckDigit (vatnumber: string) {
  let product = 10;
  let sum = 0;     
  const checkdigit = 0; 
  for (let i = 0; i < 8; i++) {
    // Każda cyfra z VATNo jest wykorzystywana do obliczeń
    sum = (Number(String(vatnumber)?.charAt(i)) + product) % 10;
    if (sum == 0) {sum = 10;}
    product = (2 * sum) % 11;
  }
  // Porównujemy wynik z liczbą kontrolną z VATNo
  if ((product + Number(String(vatnumber)?.slice(8,9))*1) % 10== 1) {return true;} else {return false;}
}

function RUVATCheckDigit (vatnumber: string) {
  // 10 cyfrowy VATNo
  if (String(vatnumber)?.length == 10) {
    let total = 0;
    const multipliers = [2,4,10,3,5,9,4,6,8,0];
    // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
    for (let i = 0; i < 10; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
    total = total % 11
    if (total > 9) {total = total % 10;}
    // Porównujemy wynik z liczbą kontrolną z VATNo
    if (total == Number(String(vatnumber)?.slice(9,10))) {return true;} else {return false;}
  // 12 cyfrowy VATNo
  } else if(String(vatnumber)?.length == 12) {
    let total1 = 0;
    const multipliers1 = [7,2,4,10,3,5,9,4,6,8,0]
    let total2 = 0;
    const multipliers2 = [3,7,2,4,10,3,5,9,4,6,8,0]
    // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
    for (let i = 0; i < 11; i++) {total1 += Number(String(vatnumber)?.charAt(i)) * multipliers1[i];}
    total1 = total1 % 11;
    if (total1 > 9) {total1 = total1 % 10;}
    // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
    for (let i = 0; i < 11; i++) {total2 += Number(String(vatnumber)?.charAt(i)) * multipliers2[i];}
    total2 = total2 % 11;
    if (total2 > 9) {total2 = total2 % 10;}
    // Porównujemy wynik z liczbą kontrolną z VATNo
    if ((total1 == Number(String(vatnumber)?.slice(10,11))) && (total2 == Number(String(vatnumber)?.slice(11,12)))) {return true;} else {return false;}
  }
}

function SEVATCheckDigit (vatnumber: string) {
  // Obliczamy R -> R = R1 + R3 + R5 + R7 + R9, and Ri = INT(Ci/5) + (Ci*2) modulo 10
  let R = 0;
  let digit;
  for (let i = 0; i < 9; i=i+2) {
    digit = Number(String(vatnumber)?.charAt(i));
    R += Math.floor(digit / 5)  + ((digit * 2) % 10);
  }
  // Obliczamy S -> S = C2 + C4 + C6 + C8
  let S = 0;
  for (let i = 1; i < 9; i=i+2) {S += Number(String(vatnumber)?.charAt(i));}
  // Obliczamy liczbę kontrolną
  const cd = (10 - (R + S) % 10) % 10;  
  // Porównujemy wynik z liczbą kontrolną z VATNo
  if (cd == Number(String(vatnumber)?.slice(9,10))) {return true;} else {return false;}
}

function SIVATCheckDigit (vatnumber: string) {
  let total = 0; 
  const multipliers = [8,7,6,5,4,3,2];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 7; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  // Obliczamy liczbę kontrolną
  total = 11 - total % 11;
  if (total == 10) {total = 0;}
  // Porównujemy wynik z liczbą kontrolną z VATNo
  if (total != 11 && total == Number(String(vatnumber)?.slice(7,8))) {return true;} else {return false;}
}

function SKVATCheckDigit (vatnumber: string) {
  // Obliczamy liczbę kontrolną i porównujemy wynik z liczbą kontrolną z VATNo
  if (Number(vatnumber) % 11 == 0) {return true;} else {return false;}
}

function NZVATCheckDigit (vatnumber: string) {
  let total = 0; 
  const multipliers = [1,3,1,3,1,3,1,3,1,3,1,3];
  // Każda cyfra z VATNo jest mnożona przez odpowiednią cyfrę z tablicy multipliers i dodawana do totala 
  for (let i = 0; i < 7; i++) {total += Number(String(vatnumber)?.charAt(i)) * multipliers[i];}
  //total = total % 10;
  if (total != 11 && total == Number(String(vatnumber)?.slice(7,8))) {return true;} else {return false;}
}